body {
  background: url('./assets/img/kaban-bg.png');
  background-size: 200px 200px;
  text-align: center;
  font-family: sans-serif;
}
a, a:visited {
  color: #941A1A;
}
a:hover {
  color: #440C0C;
  text-decoration: none;
}
.logo {
  margin-top: 10vw;
  width: 50vw;
  opacity: .7;
}
.countdown {
  font-size: 160%;
  opacity: .7;
}
.salo-background {
  opacity: .4;
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
}
.polkabana {
  position: relative;
  z-index: 5;
}

video::-webkit-media-controls {
  display: none;
}
